import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Cookies from 'universal-cookie';
import Send from './Send.js';
import Export from './Export.js';

export class Dashboard extends Component {
    constructor(base) {
        super();
        const cookies = new Cookies();
        var cook = cookies.get('XPP-L');
        var redirect = false;
        if (cook === null || cook === undefined) {
            redirect = true;
        }
        this.state = {
            redirect: redirect,
            selected: 1
        };
    }

    render() {
        return (
            <div>
                {this.state.redirect ? <Redirect to='/Login'/> : <></>}

                <div className="select-block">
                    <p className={this.state.selected === 1 ? 'selected-block' : ''} onClick={() => this.setState({ selected: 1 })}>Розсилка повідомлень</p>
                    <p className={this.state.selected === 0 ? 'selected-block' : ''} onClick={() => this.setState({ selected: 0 })}>Користувачі</p>
                    <p style={{ display: 'none' }} onClick={() => { fetch('Dashboard/TestEvent'); }}>Send Test Event</p>
                </div>
                <hr style={{ marginTop: '-17px' }} />
                <div style={{ display: this.state.selected === 0 ? '' : 'none' }}> <Export /></div>
                <div style={{ display: this.state.selected === 1 ? '' : 'none' }}> <Send /></div>
               
            </div>
        );
    }
}
export default Dashboard;
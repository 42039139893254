import React, { Component } from 'react';


export class Export extends Component {
    constructor(base) {
        super();
        this.state = {
         
        };
    }

    getExcel = () => {
        fetch('Dashboard/GetUsersExl').then(response => {
            const filename = response.headers.get('Content-Disposition').replace('attachment; filename="', '').split('"')[0];
            response.blob().then(blob => this.openInNewTab(window.URL.createObjectURL(blob), filename))
        });
        //fetch('Dashboard/GetUsersExl').then(res => res.blob()).then(blob => this.openInNewTab(window.URL.createObjectURL(blob))/*{
        /*    const href = window.URL.createObjectURL(blob);
            const a = this.linkRef.current;
            a.download = "viber.xlsx";
            a.href = href;
            a.click();
            a.href = '';

        });*/
    }
    openInNewTab = (url, name) => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(url, name);
        /*const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null*/
    }

    render() {
        return (
            <div className="send-block">
                <span>Експорт респондентів які встановили бот</span>
                <br />
                <br />
                <button onClick={() => this.getExcel()} className="btn-exp">Експортувати</button>
            </div>
        );
    }
}
export default Export;
import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router';

export class Login extends Component {
    constructor(base) {
        super();
        const cookies = new Cookies();
        var cook = cookies.get('XPP-L');
        var redirect = true;
        if (cook === null || cook === undefined) {
            redirect = false;
        }
        this.state = {
            redirect: redirect
        };
        this.state = {
            login: '',
            password: '',
            bad: false,
            redirect: redirect
        };
    }
    login = () => {
        var json = {
            login: this.state.login,
            password: this.state.password
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        };
        fetch('Dashboard/LoginP', requestOptions).then(x => x.text()).then(x => {
            if (x.length > 0) {
                const cookies = new Cookies();
                cookies.set('XPP-L', x, { maxAge: 3600 *12 });
                this.setState({ redirect: true });
                //cookie monster
            } else {
                this.setState({ bad: true });
            }
        });
    }
    render() {
        return (
            <div className="login-block">
                {this.state.redirect ? <Redirect to='/Dashboard' /> : <></>}
                <label>Логін<br /><input onInput={(e) => this.setState({ login: e.target.value })} type="text" /></label>
                <label>Пароль<br /><input onInput={(e) => this.setState({ password: e.target.value })} type="password" /></label>
                <button onClick={ () => this.login()} className="btn">Увійти</button>
                <p style={{color: 'red', display: this.state.bad ? '' : 'none'}}>Не правильний логін чи пароль</p>
            </div>
            );
    }
}
export default Login;
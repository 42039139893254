import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { Layout } from './components/Layout.js';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import Login from './components/Login.js';
import Dashboard from './components/Dashboard.js';
import { Counter } from './components/Counter';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/'  >
                    <Redirect to='/Login'/>
                </Route>
                <Route path='/Login' component={Login} />
                <Route path='/Dashboard' component={Dashboard} />
            </Layout>
        );
    }
}
